//=================================================
// Guide stops
//=================================================

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    cleanText,
    createMarkup,
    setFocus,
} from '../../../utilities';
import { pauseYT, setupVideos } from '../../../videos';
import {
    getStopItems,
    openStopOverlay,
    pushGuideEvent,
} from '../../utilities';
import { updateLinkRails } from '../../../navigation';

// Disable next/prev if appropriate
export function updateControls() {
    const currentItem = document.querySelector('.playlist-item.active');
    const prev = document.querySelector('.audio-player__left');
    const next = document.querySelector('.audio-player__right');
    if (currentItem && prev && next) {
        next.disabled = !currentItem.parentElement.nextSibling;
        prev.disabled = !currentItem.parentElement.previousSibling;
    }
}

// Close small overlay
export function closeStopOverlay() {
    // Remove all the classes related to the small-screen stop overlay
    document.querySelector('.guide__secondary')?.classList.remove('active');
    document.body.classList.remove('stop-open');
    document.querySelector('.audio-player')?.classList.remove('audio-player--expanded');

    pauseYT();
}

// show the first (usually audio) item if no item id is provided
function getCurrentStopItem(stopItems, stopItemId) {
    const idMatch = stopItems.filter((item) => item.data.id === stopItemId)[0];
    const firstItem = stopItems[0];
    const stopItem = idMatch || firstItem;

    return stopItem.data;
}

export default function Stop({ guide, stop, language, stopShouldPlay, onStopShouldPlay }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamsObj = Object.fromEntries(searchParams);
    const { stop_item: stopItemId } = searchParamsObj;
    const stopItems = getStopItems(stop, language);
    const stopItem = getCurrentStopItem(stopItems, stopItemId);
    const isOpen = searchParamsObj.open === 'true';

    function handleChangeItem(e, selectedItem) {
        e.preventDefault();

        onStopShouldPlay(true);
        setSearchParams({ ...searchParamsObj, stop_item: selectedItem.id });

        let value = cleanText(stop.attributes.title);
        if (stop.attributes.stop_number) {
            value += ` (${ stop.attributes.stop_number })`;
        }

        pushGuideEvent({ label: 'stop item', value, type: selectedItem.attributes.item_type }, guide);
    }

    function handleStopCloseClick() {
        closeStopOverlay();
        const newSearchParams = searchParamsObj;
        delete newSearchParams.open;
        setSearchParams(newSearchParams);
        setFocus('.playlist-item.active');
        pushGuideEvent({ label: 'click', type: '', value: 'close stop' }, guide);
    }

    useEffect(() => {
        const audioWrapper = document.querySelector('.audio-wrapper');

        if (!stop || !audioWrapper) return;

        const audioPlay = audioWrapper.querySelector('.audio-player__play');
        const audioProgress = audioWrapper.querySelector('.audio-player__progress');
        const audioInfoText = audioWrapper.querySelector('.audio-player__info-text');
        const audio = audioWrapper.querySelector('audio');
        const cleanTitle = cleanText(stop.attributes.title);

        // Pause any playing audio and update content unless the selected audio is already loaded
        if (audio &&
            stopItem.attributes.file?.url && (
                !audioWrapper?.classList.contains('active') ||
                (audio.src !== stopItem.attributes.file.url))) {
            audio.pause();
            // Add metadata into audio element for pickup later
            audio.dataset.label = cleanTitle;
            audio.dataset.artist = cleanText(guide.attributes.title);
            if (stop.attributes.cover_media_file) {
                audio.dataset.image = stop.attributes.cover_media_file.url;
            }
            audio.setAttribute('title', cleanTitle);

            audioPlay.classList.remove('playing');
            audioPlay.classList.add('paused');

            // Set visible audio title
            let text = `<strong>${ stop.attributes.title }</strong>`;
            if (stop.attributes.stop_number) {
                text = `<strong><span class="audio-player__info-number">${ stop.attributes.stop_number }—</span>${ stop.attributes.title }</strong>`;
            }
            audioInfoText.innerHTML = text;

            // Handle loading in content
            if (stopItem.attributes.media_type === 'Audio') {
                audioProgress.style.width = '0px';
                audio.src = stopItem.attributes.file.url;
                audio.load();

                if (stopShouldPlay) {
                    audioPlay.classList.add('loading');
                    audio.play().then(() => {
                        audioPlay.classList.add('playing');
                        audioPlay.classList.remove('paused', 'loading');
                    }).catch(() => {
                        audioPlay.classList.remove('playing', 'loading');
                        audioPlay.classList.add('paused');
                    });
                }

                audioWrapper.classList.add('active');
            }
        } else if (stopItem.attributes.file?.url) {
            audioWrapper.classList.add('active');
        } else {
            // If there's no audio, hide and pause the player
            audio.pause();
            audioWrapper.classList.remove('active');
        }

        // Set focus/open stop (not initial load)
        if (isOpen) {
            if (!window.isNavSmall) {
                // Move focus to the player for keyboard users
                setFocus('.audio-player__play');
            }

            openStopOverlay();
        } else {
            closeStopOverlay();
        }

        // Scroll to the top of the stop section if on desktop
        const guideStopEl = document.querySelector('.guide-stop');
        if (guideStopEl) {
            guideStopEl.scrollTop = 0;
        }

        updateControls();
        updateLinkRails();
        setupVideos();
        // When hitting the back button the in the browser, don't auto play, unless user has selected an item
        if (!stopItemId) {
            onStopShouldPlay(false);
        }

        // Save the current state in the audio player DOM so that we can navigate back here from the guide index page if someone clicks on the audio player
        if (audioWrapper) {
            const activeItemState = {
                guideId: guide.attributes.id,
                params: searchParamsObj,
            };
            audioWrapper.dataset.activeItem = JSON.stringify(activeItemState);
        }
    }, [stopItem?.id]);

    useEffect(() => {
        if (isOpen) {
            openStopOverlay();
        } else {
            closeStopOverlay();
        }
    }, [isOpen]);

    if (!stop) return null;

    let content;
    let itemTypeSelector;
    let media;
    let mediaCaption;

    // const mediaNative = ['Video'].includes(stopItem.attributes.media_type) ?
    //     stopItem.attributes.native_media_html :
    //     stop.attributes.cover_media_html;
    //
    // if (mediaNative) {
    //     media =
    //         <div className="guide-stop__media" dangerouslySetInnerHTML={ createMarkup(mediaNative) }/>;
    // }

    if (stopItems.length > 1) {
        itemTypeSelector =
            <div className="link-rail-wrapper link-rail-wrapper--guide">
                <button className="link-rail__left" aria-label="left"></button>
                <ul className="link-rail mb-m">
                    { stopItems.map((item) => {
                        const itemType = item.data.attributes.item_type;
                        const activeClass = item.data.id === stopItem.id ? ' active' : '';

                        return (
                            <li className='link-rail__item' key={ item.data.id }>
                                <a href=""
                                   className={ `link-rail__link${ activeClass }` }
                                   onClick={ (e) => handleChangeItem(e, item.data) }>
                                    { /*{ itemTypeDisplay(itemType, language) }*/ }
                                    { language !== item.data.attributes.item_language ? I18n[language][`${ itemType }_english_only`] : I18n[language][itemType] }
                                </a>
                            </li>
                        );
                    }) }
                </ul>
                <button className="link-rail__right" aria-label="right"></button>
            </div>;
    }

    if (stopItem.attributes.content) {
        content =
            <div className="guide-stop__content"
                 dangerouslySetInnerHTML={ createMarkup(stopItem.attributes.content) }/>;
    }

    if (stop.attributes.cover_media_html) {
        media = <div className="guide-stop__media"
                     dangerouslySetInnerHTML={ createMarkup(stop.attributes.cover_media_html) }/>;
    }

    if (stop.attributes.cover_media_description) {
        mediaCaption =
            <div>
                <hr className="guide-stop__hr"/>
                <div className="body-medium mt-s"
                     dangerouslySetInnerHTML={ createMarkup(stop.attributes.cover_media_description) }/>
            </div>;
    }

    return (
        <div className="guide-stop">
            <div className="guide-stop-controls">
                <button
                    className={ `guide-stop-controls__close guide-back active${ (language === 'es') ? ' es' : '' }` }
                    onClick={ handleStopCloseClick }>
                    { I18n[language].back }
                </button>
            </div>
            <div className="guide-stop-content-wrapper">
                { media }
                { itemTypeSelector }
                { content }
                { mediaCaption }
            </div>
        </div>
    );
}
