//=================================================
// Sunrise Sunset
//=================================================

const SunCalc = require('suncalc');

export default function setupSunriseSunset() {
    const NYC_LAT = 40.739609;
    const NYC_LONG = -74.00886;
    const SIXTY_SECONDS = 60000;
    const THIRTY_SECONDS = 30000;

    const sun = document.querySelector('.sunrise-sunset');
    const iframe = sun ? sun.querySelector('.sunrise-sunset__iframe') : null;
    const forceSun = document.location.search.match(/[?&]sunrise-sunset=(\w*)/);
    const sunrisePath = sun?.dataset.sunrise;
    const sunsetPath = sun?.dataset.sunset;

    function closeHandler(cb) {

        function closeCallback() {
            // Empty the iframe
            if (iframe) {
                iframe.src = '';
            }
            // Hide everything
            sun.classList?.remove('active', 'sunrise-sunset--sunrise', 'sunrise-sunset--sunset');

            cb();
        }

        // Close "X"
        sun.querySelector('.sun-legend__x')?.addEventListener('click', closeCallback, { once: true });

        // Default to 30 second timeout
        let timeout = THIRTY_SECONDS;
        const now = (new Date()).getTime();
        const triggerTime = parseInt(sessionStorage.getItem('sunrise-sunset-time'));
        const triggerTimeDistance = (triggerTime + THIRTY_SECONDS) - now;

        // If we're within 30 seconds of triggering, keep running the project on this page for the remaining time
        if (triggerTime && ((triggerTime + THIRTY_SECONDS) > now)) {
            timeout = triggerTimeDistance;
        }

        setTimeout(closeCallback, timeout);
    }

    function setSessionVariables(sunState) {
        // Track project trigger in case we don't want them to see it multiple times
        sessionStorage.setItem(sunState, true);

        // Track the initial triggering time of the project, so we can persist correct durations between pages
        const time = sessionStorage.getItem('sunrise-sunset-time');
        if (!time) {
            sessionStorage.setItem('sunrise-sunset-time', (new Date()).getTime());
        }
    }

     
    function triggerSunrise(cb) {
        if (sun && iframe) {
            function loadCallback() {
                sun.classList.add('active', 'sunrise-sunset--sunrise');
                closeHandler(cb);
                setSessionVariables('sunrise');
            }

            iframe.addEventListener('load', loadCallback, { once: true });
            iframe.src = sunrisePath;
        }
    }

    function triggerSunset(cb) {
        if (sun && iframe) {
            function loadCallback() {
                sun.classList.add('active', 'sunrise-sunset--sunset');
                closeHandler(cb);
                setSessionVariables('sunset');
            }

            iframe.addEventListener('load', loadCallback, { once: true });
            iframe.src = sunsetPath;
        }
    }

    // Result is in seconds
    function timeDistance(a, b) {
        let newA = a;
        let newB = b;
        typeof newA === 'number' || (newA = a.getTime());
        typeof newB === 'number' || (newB = b.getTime());
        return Math.abs(Math.floor(newA / 1000) - Math.floor(newB / 1000));
    }

    // Complicated logic can go here depending on the setup and teardown of the
    // artworks. Accepts a function `cb` that it should call after it cleans up.
    const SUN_DISPATCH = {
        sunset: function (cb) {
            if (!sessionStorage.getItem('sunset')) {
                triggerSunset(cb);

                if (useAnalytics) {
                    dataLayer.push({
                        event: 'sunrise_sunset',
                        label: 'sunset',
                        value: 'play',
                    });
                }
            }
        },
        sunrise: function (cb) {
            if (!sessionStorage.getItem('sunrise')) {
                triggerSunrise(cb);

                if (useAnalytics) {
                    dataLayer.push({
                        event: 'sunrise_sunset',
                        label: 'sunrise',
                        value: 'play',
                    });
                }
            }
        },
    };

    function check() {
        const date = new Date();
        const sunObj = SunCalc.getTimes(date, NYC_LAT, NYC_LONG);

        if (timeDistance(date, sunObj.sunrise) <= 60) {
            return 'sunrise';
        } else if (timeDistance(date, sunObj.sunset) <= 60) {
            return 'sunset';
        }

        return null;
    }

    function checkPromise() {
        const data = check();

        if (!data) {
            setTimeout(() => {
                checkPromise();
            }, SIXTY_SECONDS);

        } else {
            SUN_DISPATCH[data](resumeChecks);
        }
    }

     

    function resumeChecks() {
        setTimeout(() => {
            checkPromise();
        }, SIXTY_SECONDS);
    }

    // This whole function could be rewritten, but for now expose this to the window so we can arbitrarily call it elsewhere (including signage for testing)
    window.forceSunriseSunset = (type) => {
        sessionStorage.removeItem('sunrise');
        sessionStorage.removeItem('sunset');
        sessionStorage.removeItem('sunrise-sunset-time');
        SUN_DISPATCH[type](resumeChecks);
    };

    // Check for query triggers
    if (forceSun) {
        window.forceSunriseSunset(forceSun[1]);
    }

    checkPromise();
}
