//=================================================
// Guide footer
//=================================================

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getDark, setDark } from '../../dark';
import { getLanguage } from '../utilities';

const images = require.context('../../../../images/', true);

export default function Footer() {
    const [searchParams] = useSearchParams();
    const language = getLanguage(searchParams);
    const [checked, setChecked] = useState(getDark() === 'true');

    function handleDarkModeClick() {
        setChecked(!checked);

        const dark = getDark() === 'false';
        setDark(dark ? 'true' : 'false');

        if (useAnalytics) {
            dataLayer.push({
                event: 'buttons',
                label: 'dark toggle',
                value: dark ? 'true' : 'false',
            });
        }
    }

    return (
        <footer className="guide-footer">
            <label htmlFor="dark-mode" className="checkbox">
                <input id="dark-mode" className="checkbox__input" type="checkbox"
                       checked={ checked }
                       onChange={ () => handleDarkModeClick() }/>
                { I18n[language].dark }
            </label>

            <div className="guide-footer__list">
                <p className="large m-0">
                    { I18n[language].see_more_on }
                    &nbsp;
                    <a href="/" className="underline">whitney.org</a>
                </p>
            </div>
        </footer>
    );
}
