// Get a reasonable dark variable
export function getDark() {
    const dark = localStorage.getItem('dark');
    if (!dark) {
        return 'false';
    }

    return dark;
}

// Set dark mode and any other meta dark options
export function setDark(dark) {
    // Make sure the value is allowed, otherwise default to false
    if (['true', 'false'].includes(dark)) {
        localStorage.setItem('dark', dark);
    } else {
        localStorage.setItem('dark', 'false');
    }

    if (dark === 'true') {
        document.body.classList.add('dark');
        document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#000000');
    } else {
        document.body.classList.remove('dark');
        document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#FFFFFF');
    }
}
