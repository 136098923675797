/*eslint consistent-return: "off"*/
// Custom Mailchimp signup form
// adapted from https://css-tricks.com/form-validation-part-4-validating-mailchimp-subscribe-form/
const serialize = (form) => {
    // Setup our serialized data
    let serialized = '';

    // Loop through each field in the form
    for (let i = 0; i < form.elements.length; i++) {

        const field = form.elements[i];

        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        const isValidFieldElement = !(
            !field.name ||
            field.disabled ||
            field.type === 'file' ||
            field.type === 'reset' ||
            field.type === 'submit' ||
            field.type === 'button'
        );

        // Convert field data to a query string
        if (isValidFieldElement && ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked)) {
            serialized += `&${ encodeURIComponent(field.name) }=${ encodeURIComponent(field.value) }`;
        }
    }

    return serialized;
};

function setupMailchimpFunction() {
    // Validate the field
    const hasError = (field) => {
        // Don't validate submits, buttons, file and reset inputs, and disabled fields
        if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') return;

        // Get validity
        const { validity } = field;

        // If valid, return null
        if (validity.valid) return;

        // If field is required and empty
        if (validity.valueMissing) return 'Please fill out this field.';

        // If not the right type
        if (validity.typeMismatch) {
            // Email
            if (field.type === 'email') return 'Please enter an email address.';

            // URL
            if (field.type === 'url') return 'Please enter a URL.';
        }

        // If too short
        if (validity.tooShort) return `Please lengthen this text to ${ field.getAttribute('minLength') } characters or more. You are currently using ${ field.value.length } characters.`;

        // If too long
        if (validity.tooLong) return `Please shorten this text to no more than ${ field.getAttribute('maxLength') } characters. You are currently using ${ field.value.length } characters.`;

        // If pattern doesn't match
        if (validity.patternMismatch) {
            // If pattern info is included, return custom error
            if (field.hasAttribute('title')) return field.getAttribute('title');

            // Otherwise, generic error
            return 'Please match the requested format.';
        }

        // If number input isn't a number
        if (validity.badInput) return 'Please enter a number.';

        // If a number value doesn't match the step interval
        if (validity.stepMismatch) return 'Please select a valid value.';

        // If a number field is over the max
        if (validity.rangeOverflow) return `Please select a value that is no more than ${ field.getAttribute('max') }.`;

        // If a number field is below the min
        if (validity.rangeUnderflow) return `Please select a value that is no less than ${ field.getAttribute('min') }.`;

        // If all else fails, return a generic catchall error
        return 'The value you entered for this field is invalid.';
    };

    // Show an error message
    const showError = (fieldArg, error) => {
        let field = fieldArg;
        // Add error class to field
        field.classList.add('error');

        // If the field is a radio button and part of a group, error all and get the last item in the group
        if (field.type === 'radio' && field.name) {
            const group = field.form.querySelectorAll(`[name="${ field.name }"]`);
            if (group.length > 0) {
                for (let i = 0; i < group.length; i++) {
                    group[i].classList.add('error');
                }
                field = group[group.length - 1];
            }
        }

        // Get field id or name
        const id = field.id || field.name;
        if (!id) return;
        const errorId = `error-for-${ id }`;

        // Check if error message field already exists
        // If not, create one
        let message = field.form.querySelector('.mailchimp__response__error');
        if (!message) {
            message = document.createElement('div');
            message.className = 'mailchimp__response mailchimp__response__error';
            message.id = errorId;

            const responses = field.form.querySelector('.mailchimp__responses');
            responses.appendChild(message);
        }

        // Add ARIA role to the field
        field.setAttribute('aria-describedby', errorId);

        // Update error message
        message.innerHTML = error;

        // Show error message
        message.style.display = 'block';
        message.style.visibility = 'visible';
    };

    const submitMailChimpForm = (form) => {
        // Temporarily store the form's unique ID to namespace multiple forms on the same page
        const mailchimpInstanceId = form.closest('.mailchimp').getAttribute('id');
        localStorage.setItem('mailchimpInstanceId', mailchimpInstanceId);

        // Get the Submit URL
        let url = form.getAttribute('action');
        url = url.replace('/post?u=', '/post-json?u=');
        url += `${ serialize(form) }&c=displayMailChimpStatus`;

        // Create script with url and callback (if specified)
        const script = window.document.createElement('script');
        script.src = url;

        // Insert script tag into the DOM (append to <head>)
        const ref = window.document.getElementsByTagName('script')[0];
        ref.parentNode.insertBefore(script, ref);

        // After the script is loaded (and executed), remove it
        script.onload = () => {
            this.remove();
        };
    };

    const handleSubmit = (e) => {
        // Only target Mailchimp forms
        if (!e.target.classList.contains('mailchimp__validate')) return true;

        // Get all of the form elements
        const fields = e.target.elements;

        // Validate each field
        // Store the first field with an error to a variable so we can bring it into focus later
        let error;
        let hasErrors;
        for (let i = 0; i < fields.length; i++) {
            error = hasError(fields[i]);
            if (error) {
                showError(fields[i], error);
                if (!hasErrors) {
                    hasErrors = fields[i];
                }
            }
        }

        // If there are errrors, don't submit form and focus on first element with error
        if (hasErrors) {
            hasErrors.focus();
            return;
        }

        const errors = e.target.querySelector('.mailchimp__response__error');
        if (errors) errors.remove();

        const input = e.target.querySelector('.mailchimp__input');
        if (input) input.classList.remove('error');

        // Otherwise, let the form submit normally
        // You could also bolt in an Ajax form submit process here
        submitMailChimpForm(e.target);
    };

    window.displayMailChimpStatus = (data) => {
        const mailchimpInstanceId = localStorage.getItem('mailchimpInstanceId');
        localStorage.removeItem('mailchimpInstanceId');
        const mailchimpElement = document.querySelector(`#${ mailchimpInstanceId }`);
        let mcStatus = mailchimpElement.querySelector('.mailchimp__response__success');

        if (!mcStatus) {
            const message = document.createElement('div');
            message.className = 'mailchimp__response mailchimp__response__success';

            const responses = field.form.querySelector('.mailchimp__responses');
            responses.appendChild(message);

            mcStatus = mailchimpElement.querySelector('.mailchimp__response__success');
        }

        // Update our status message
        mcStatus.innerHTML = data.msg;

        // Bring our status message into focus
        mcStatus.setAttribute('tabindex', '-1');
        mcStatus.focus();

        // If error, add error class
        if (data.result === 'error') {
            mcStatus.classList.remove('mailchimp__response__success');
            mcStatus.classList.add('mailchimp__response__error');
            return;
        }

        // Otherwise, add success class
        mcStatus.classList.remove('mailchimp__response__error');
        mcStatus.classList.add('mailchimp__response__success');
    };

    // Add the novalidate attribute when the JS loads
    const forms = document.querySelectorAll('.mailchimp__validate');
    forms.forEach((el) => {
        el.setAttribute('novalidate', 'true');
        el.addEventListener('submit', (e) => {
            e.preventDefault();
            handleSubmit(e);
        });
    });
}

export default function setupMailchimp() {
    // prevent Javascript from loading on every page
    if (document.querySelector('.mailchimp')) {
        setupMailchimpFunction();
    }
}
