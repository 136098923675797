//=================================================
// Lightbox
//=================================================

import { setupAudio } from './audio';
import { setupVideos } from './videos';
import { scrollPosition, trapTabs } from './utilities';

// Unsetting listeners doesn't work well with ordering so disable that here
 

// Handle keyboard controls
function keyListener(e) {
    if (e.key === 'Escape') {
        lightboxClose();
    } else if (e.key === 'ArrowRight') {
        document.querySelector('.lightbox-overlay.active .lightbox-header__next')?.click();
    } else if (e.key === 'ArrowLeft') {
        document.querySelector('.lightbox-overlay.active .lightbox-header__prev')?.click();
    }
}

export function lightboxClose() {
    const overlay = document.querySelector('.lightbox-overlay.active');
    if (overlay) {
        const lightbox = overlay.querySelector('.lightbox');

        overlay.classList.remove('active');
        overlay.innerHTML = '';
        document.body.classList.remove('overlay-open');
        document.removeEventListener('click', lightboxOutsideListener);
        document.removeEventListener('keydown', keyListener);

        // Focus on last expanded lightbox preview, but keep our current scroll position so it doesn't jump
        const lastScrollPosition = scrollPosition();
        document.querySelector(`.lightbox-preview[data-id="${ lightbox.dataset.id }"]`)?.focus();
        window.scrollTo({ top: lastScrollPosition });
    }
}

// Handle clicks outside the lightbox (for close)
function lightboxOutsideListener(e) {
    if (!e.target.closest('.lightbox-overlay') || e.target.classList.contains('lightbox-header__close')) {
        lightboxClose();
    }
}

// Setup the next/prev and count controls in the opening lightbox
function setControls(overlay, wrapper) {
    const galleryLightboxes = document.querySelectorAll(`.lightbox-wrapper[data-gallery="${ wrapper.dataset.gallery }"]`);
    const count = overlay.querySelector('.lightbox-header__count');
    const prev = overlay.querySelector('.lightbox-header__prev');
    const next = overlay.querySelector('.lightbox-header__next');

    // If there's more than 1 other lightbox, figure out what controls to show...otherwise hide them all
    if (galleryLightboxes.length > 1) {
        const index = Array.prototype.indexOf.call(galleryLightboxes, wrapper);

        // Previous
        if (index > 0) {
            prev.addEventListener('click', (e) => {
                e.stopPropagation();
                loadLightbox(galleryLightboxes[index - 1]);
            });
        } else {
            prev.disabled = true;
        }

        // Next
        if (index + 1 < galleryLightboxes.length) {
            next.addEventListener('click', (e) => {
                e.stopPropagation();
                loadLightbox(galleryLightboxes[index + 1]);
            });
        } else {
            next.disabled = true;
        }

        count.innerHTML = `${ index + 1 } / ${ galleryLightboxes.length }`;
        [prev, next, count].forEach((el) => {
            el.classList.add('active');
        });

    } else {
        [prev, next, count].forEach((el) => {
            el.classList.remove('active');
            el.disabled = true;
        });
    }
}

 

// Load the lightbox from a given wrapper
function loadLightbox(wrapper) {
    const lightbox = wrapper.querySelector('.lightbox');

    // Make the lightbox if it doesn't already exist (needs to be at root of dom for styling/event handling)
    let overlay = document.querySelector('.lightbox-overlay');
    if (overlay) {
        overlay.innerHTML = '';
    } else {
        overlay = document.createElement('div');
        overlay.setAttribute('role', 'dialog');
        overlay.setAttribute('aria-modal', 'true');
        overlay.classList.add('lightbox-overlay', 'active');
        document.body.append(overlay);
    }

    // Clone content into the lightbox
    overlay.append(lightbox.cloneNode(true));
    overlay.classList.add('active');
    document.body.classList.add('overlay-open');

    // Need to reset any initializations, due to deep cloning for the lightbox
    overlay.querySelectorAll('*[data-initialized="true"]').forEach((el) => {
        el.removeAttribute('data-initialized');
    });

    setupAudio();
    setupVideos();
    setControls(overlay, wrapper);
    trapTabs(overlay);

    // Setup close, key, and resize handlers
    document.addEventListener('click', lightboxOutsideListener);
    document.addEventListener('keydown', keyListener);
}

export default function setupLightboxes() {
    document.querySelectorAll('.lightbox-wrapper').forEach((wrapper) => {
        wrapper.querySelector('.lightbox-preview')?.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            loadLightbox(wrapper);
        });
    });
}
