//=================================================
// Strobe text animation effect 
//=================================================

export default function setupStrobe() {
    document.querySelectorAll('.strobe').forEach((slider) => {
        // start animating when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) slider.classList.add('strobe__active');
            });
        });

        observer.observe(slider);
    });
}
