import { trapTabs } from './utilities';

 
export function setupOverlay(overlay = document.querySelector('.overlay')) {
    const toggle = document.querySelector('.overlay__toggle');

    if (!overlay || !toggle) {
        return;
    }

    const overlayCloseButton = overlay.querySelector('.overlay-header__close');

    // Listen on overlay toggle
    toggle.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Necessary to get the grey background behind the overlay
        document.body.classList.add('overlay-open');
        // Setup close, key, and resize handlers
        document.addEventListener('click', overlayOutsideListener);
        document.addEventListener('keydown', overlayKeyListener);
        overlayCloseButton.addEventListener('click', overlayClose);
        toggle.setAttribute('aria-expanded', 'true');

        overlay.classList.add('active');
        trapTabs(overlay);
    });
}

// Close any overlay
export function overlayClose() {
    const overlay = document.querySelector('.overlay');
    if (overlay) {
        overlay.classList.remove('active');
        document.body.classList.remove('overlay-open');
        document.removeEventListener('click', overlayOutsideListener);
        document.removeEventListener('keydown', overlayKeyListener);

        // Reset any overlay toggles
        const toggle = document.querySelector('.overlay__toggle[aria-expanded="true"]');
        if (toggle) {
            toggle.setAttribute('aria-expanded', 'false');
            toggle.focus();
        }
    }
}

export function overlayKeyListener(e) {
    if (e.key === 'Escape') {
        overlayClose();
    }
}

export function overlayOutsideListener(e) {
    // Make sure the click is outside the overlay, or the close button
    if (!e.target.closest('.overlay') || e.target.classList.contains('overlay__close')) {
        overlayClose();
    }
}
